<template>
  <!-- BEGIN: Content-->
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-0">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">
                <small>{{ convo.title }} evaluation</small><br><strong class="text-dark">{{ user.full_name }}</strong>
              </h2>
            </div>
          </div>
          <p
            v-if="promoEvaluation.formatted_date"
            class="badge bg-light-success"
          >
            <i data-feather="check" /> Submitted: {{ promoEvaluation.formatted_date }}
          </p>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Back to list"
              @click="$router.push({name: 'evaluator.calls.promotion.evaluations'})"
            ><i data-feather="chevron-left" /></a>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">
            <p>This form must be written in English. The information is confidential. It is not going to be distributed. Only the Promotion Committee members, the final panel of evaluators, will have access to it. Please comment briefly on the following as appropriate. </p>
            <p>For any question related to this assessment, please contact Sònia Salvadó at <a href="mailto:ssalvado@icrea.cat">ssalvado@icrea.cat</a>.</p>

            <div class="card">
              <div class="card-header pb-0">
                <h3 class="card-title">
                  <h4
                    v-if="loggedUser.is_pc_member_active"
                    class="mb-2"
                  >
                    PC Member evaluator: <strong>{{ loggedUser.full_name }}</strong> - <strong>{{ loggedUser.hosts && loggedUser.hosts[0] ? loggedUser.hosts[0].name : '-' }}</strong>
                  </h4>
                  <h4
                    v-else-if="loggedUser.roles.includes('evaluator-host')"
                    class="mb-2"
                  >
                    Host evaluator: <strong>{{ loggedUser.full_name }}</strong> - <strong>{{ loggedUser.hosts && loggedUser.hosts[0] ? loggedUser.hosts[0].name : '-' }}</strong>
                  </h4>
                  <h4
                    v-else
                    class="mb-2"
                  >
                    Peer evaluator: <strong>{{ loggedUser.full_name }}</strong> - <strong>{{ loggedUser.hosts && loggedUser.hosts[0] ? loggedUser.hosts[0].name : '-' }}</strong>
                  </h4>
                  <a data-action="collapse">1. Evaluation details</a>
                </h3>
              </div>

              <!-- FORM CONTENT -->
              <PCMemberEvaluation v-if="loggedUser.is_pc_member_active" />
              <HostEvaluation v-else-if="loggedUser.roles.includes('evaluator-host')" />
              <PeerEvaluation v-else />
            </div>

            <HostEvaluationAdditional v-if="loggedUser.roles.includes('evaluator-host')" />
          </div>
        </div>

        <div class="page-actions d-flex align-items-center justify-content-between">
          <div>
            <button
              id="type-success"
              class="btn btn-info me-21"
              @click="submitEvaluation(false)"
            >
              <i data-feather="command" /> Save draft
            </button>
            <!-- <button class="btn btn-outline-info" type="button" disabled="">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            <span class="ms-25 align-middle">Updating...</span>
                        </button> -->
            <button
              id="type-success"
              class="btn btn-success ms-1"
              @click="submitEvaluation(true)"
            >
              <i data-feather="save" /> Save and Submit
            </button>
            <!-- <button class="btn btn-outline-success" type="button" disabled="">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            <span class="ms-25 align-middle">Saving...</span>
                        </button> -->
          </div>
          <div v-if="promoEvaluation.id">
            <button
              id="type-success"
              class="btn btn-danger"
              @click="deleteEvaluation"
            >
              <i data-feather="save" /> Delete
            </button>
          </div>

        </div>

      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import { mapGetters } from 'vuex'
import 'vue2-datepicker/index.css'
import Vue from 'vue'
import PeerEvaluation from './evaluations/forms/PeerEvaluation.vue'
import PCMemberEvaluation from './evaluations/forms/PCMemberEvaluation.vue'
import HostEvaluation from './evaluations/forms/HostEvaluation.vue'
import HostEvaluationAdditional from './evaluations/forms/HostEvaluationAdditional.vue'

export default {
  components: {
    HostEvaluationAdditional,
    HostEvaluation,
    PCMemberEvaluation,
    PeerEvaluation,
  },
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters({
      convo: 'convo/convo',
      promoEvaluation: 'convo/promoEvaluation',
      user: 'users/user',
      loggedUser: 'auth/admin',
    }),
  },
  async mounted() {
    this.$store.dispatch('convo/clearPromoEvaluation')
    await this.$store.dispatch('convo/fetchSpecific', this.$route.query.convo)
    await this.$store.dispatch('users/fetchUser', this.$route.query.user)
    await this.$store.dispatch('convo/fetchPromoEvaluation', { convo: this.$route.query.convo, user: this.$route.query.user })
    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 100)
  },
  methods: {
    async submitEvaluation(submit) {
      const data = await this.changeEvaluation({
        researcher: this.user.id,
        evaluation: this.promoEvaluation,
        convo: this.convo.id,
        submit,
      })

      if (data.status < 205) {
        Vue.swal('Evaluation updated successfully!', '', 'success')
        this.$store.dispatch('convo/fetchPromoEvaluation', { convo: this.$route.query.convo, user: this.$route.query.user })
      }
    },
    async deleteEvaluation() {
      Vue.swal({
        title: 'Do you want to delete this evaluation?',
        html: '',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Yes',
      }).then(async result => {
        if (result.isConfirmed) {
          const data = await this.changeEvaluation({
            evaluation: this.promoEvaluation,
            delete: true,
          })

          if (data.status < 205) {
            Vue.swal('Evaluation deleted successfully!', '', 'success')
            this.$store.dispatch('convo/clearPromoEvaluation')
          }
        }
      })
    },
    async changeEvaluation(data) {
      const result = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/convo/set-evaluation`, data)
      return result
    },
  },
}
</script>
